<template>
  <section class="app-ecommerce-details">
    <b-card v-if="product" no-body>
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="product.imagen ? product.imagen : defaultImage"
                :alt="`Image of ${product.name}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4 class="font-weight-bolder">{{ product.name }}</h4>
            <div v-if="product.documents.length" class="mt-3">
              <p class="font-weight-bolder mb-50">
                {{ $t("documentacion") }}
              </p>
              <p v-for="document in product.documents" :key="document.id">
                <b-link
                  :href="document.path"
                  target="_blank"
                  class="mr-1 mb-1 link"
                >
                  {{ document.name }}
                </b-link>
              </p>
            </div>
            <p class="font-weight-bolder mt-2">
              {{ $t("ReviewType") }}
            </p>
            <p>{{ $t(product.type_review.name) }}</p>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="py-1 px-2">
        <h5 class="m-0 font-weight-bolder">{{ $t("Activos") }}</h5>
      </div>
      <b-table hover responsive :items="items" :fields="fields">
        <template #cell(image)="data">
          <b-link :to="{ name: 'viewAsset', params: { id: data.item.id } }">
            <div class="d-flex align-items-center">
              <div
                :style="`background-image: url('${
                  data.item.image ? data.item.image : defaultImage
                }');`"
                class="d-inline-block img-product-list mr-50"
              ></div>
              <p class="d-inline-block m-0">{{ data.item.name }}</p>
            </div>
          </b-link>
        </template>
        <template #cell(client)="data">
          <span v-if="data.item.client">
            {{ data.item.client.name }}
          </span>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="statusAssets[data.item.status.alias]">
            {{ $t(`status.assets.${data.item.status.alias}`) }}
          </b-badge>
        </template>
        <template #cell(last_review_date)="data">
          <span v-if="data.item.last_review_date">
            {{ dateColumn(data.item.last_review_date) }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(next_review_date)="data">
          <span v-if="data.item.next_review_date != 'null'">
            {{ dateColumn(data.item.next_review_date) }}
          </span>
          <b-badge
            v-else-if="data.item.useful_review"
            variant="light-info"
            class="ml-50"
          >
            {{ data.item.useful_review }} {{ $t("meses") }}
          </b-badge>
          <span
            v-else-if="!data.item.useful_review && data.item.next_review_date"
          >
            -
          </span>
        </template>
        <template #cell(useful_life)="data">
          <span v-if="data.item.useful_life">
            {{ data.item.useful_life }} {{ $t("meses") }}
          </span>
          <span class="" v-else-if="data.item.useful_life === null"> - </span>
          <span class="text-danger" v-else>
            {{ $t("RevisionNecesaria") }}
          </span>
        </template>
        <template #cell(actions)="data">
          <b-link
            :to="{ name: 'viewAsset', params: { id: data.item.id } }"
            class="mr-1"
          >
            <feather-icon icon="EyeIcon" size="16" />
          </b-link>
          <b-link
            v-if="data.item.archives && data.item.archives.review"
            :href="data.item.archives.review.url"
            target="_blank"
          >
            <feather-icon icon="DownloadIcon" size="16" />
          </b-link>
        </template>
      </b-table>
    </b-card>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
        <b-form-select
          v-model="pageLength"
          :options="pages"
          class="ml-50 mr-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> {{ $t("Total") }}: {{ totalItems }}</span>
      </div>
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BFormSelect,
  BTable,
  BPagination,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { TimestampToFlatPickr } from "@/libs/helpers";
import { config } from "@/shared/app.config";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BLink,
    BFormSelect,
    BPagination,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      defaultImage: require("@/assets/images/default/asset.png"),
      statusAssets: config.statusAssetsVariants,
      currentPage: 1,
      pageLength: 10,
      pages: ["10", "15", "25"],
      fields: [
        {
          label: this.$t("NombreProducto"),
          key: "image",
        },
        {
          label: this.$t("Estado"),
          key: "status",
        },
        {
          label: this.$t("UltimaRevision"),
          key: "last_review_date",
        },
        {
          label: this.$t("ProximaRevision"),
          key: "next_review_date",
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          class: "text-right",
        },
      ],
    };
  },
  watch: {
    currentPage() {
      this.chargeData();
    },
    pageLength() {
      this.chargeData();
    },
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      product: "products/getProduct",
      items: "assets/getItems",
      totalItems: "assets/getTotalItems",
    }),
  },
  methods: {
    ...mapActions({
      getProduct: "products/getProduct",
      getListAssets: "assets/getListAssets",
    }),
    chargeData() {
      const client = this.currentClient ? this.currentClient.id : "";
      this.getListAssets({
        client,
        page: this.currentPage,
        per_page: this.pageLength,
        product_id: this.product.id,
      });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    dateColumn(date) {
      return TimestampToFlatPickr(date);
    },
  },
  async created() {
    await this.getProduct(this.$route.params.id);
    await this.chargeData();
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
